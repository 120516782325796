import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './ClickOutsideDirective';
import { AutofocusDirective } from './AutofocusDirective';
import { ContenteditableValueAccessor } from './ContentEditable';
@NgModule({
    declarations: [
        ClickOutsideDirective,
        AutofocusDirective,
        ContenteditableValueAccessor
    ],
    imports: [
    ],

    exports: [
        ClickOutsideDirective,
        AutofocusDirective,
        ContenteditableValueAccessor
    ],

    providers: [],
})

export class DirectiveModule { }
