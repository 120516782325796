import { NgModule } from '@angular/core';
import { AvatarComponent } from './avatar/avatar.component';
import { TeamcardComponent } from './teamcard/teamcard.component';
import { RightmodalComponent } from './rightmodal/rightmodal.component';
import { AngularMaterialModule } from '@app/material.module';
import { PreisealarmselectedfiltersComponent } from './preisealarmselectedfilters/preisealarmselectedfilters.component';
@NgModule({
    declarations: [
        AvatarComponent,
        TeamcardComponent,
        RightmodalComponent,
        PreisealarmselectedfiltersComponent
    ],
    imports: [
        AngularMaterialModule
    ],

    exports: [
        AvatarComponent,
        TeamcardComponent,
        RightmodalComponent,
        PreisealarmselectedfiltersComponent
    ],

    providers: [
    ]
})
export class ToolsModule { }
