import { Injectable } from '@angular/core';

@Injectable(
    { providedIn: 'root' }
)
export class FacebookService {
    /**
     * load analytics
     * @param trackingID
     */
    loadFacebookPixelManager (trackingID: string): void {
        const gaNoScript = document.createElement('noscript');
        const gaScript2 = document.createElement('script');
        gaScript2.setAttribute('defer', '');
        gaScript2.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${trackingID}');fbq('track', 'PageView');`;
        gaNoScript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${trackingID}&ev=PageView&noscript=1"/>`;

        document.documentElement.firstChild.appendChild(gaNoScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }
}
