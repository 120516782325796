<main class="mh">
    <router-outlet></router-outlet>
</main>
@if(displaySite()) {
    @if(!hidenNavbar()) {

    @if(showChat()) {
    @if(!showchatpanel()) {
    <div class="mychatdiv mat-elevation-z5" (click)="openChat()">
        <button matTooltip="Chat starten" matTooltipPosition="left">
            <mat-icon aria-hidden="true" class="cc1">chat</mat-icon>
        </button>
    </div>
    } @else {
    <ng-container *ngTemplateOutlet="chatpanel"></ng-container>
    }
    @if(isBrowser() && showChatAgent() && !showchatpanel()) {
    @if(curentPartner && !curentPartner?.hideChatPopup) {
    <clever-agent (triggerclosechatagent)="closeChatAgent()"></clever-agent>
    }
    }
    }
    } @else {
    <ng-container *ngTemplateOutlet="empty"></ng-container>
    }
    @if(!hidenNavbar() && showScrollTopButton()) {
        <button mat-mini-fab class="gototop" (click)="scrollToTop()">
            <mat-icon aria-hidden="true">keyboard_arrow_up</mat-icon>
        </button>
    }
} @else {
    <ng-container *ngTemplateOutlet="secretSite"></ng-container>
}

<ng-template #notfound>
    <router-outlet></router-outlet>
</ng-template>

<ng-template #empty>
    <div class="emptyheader"></div>
</ng-template>

<ng-template #secretSite>
    <div class="secret">
        <div class="subsecret">
            <mat-form-field appearance="outline" class="w100">
                <mat-icon aria-hidden="true" matPrefix>directions_walk</mat-icon>
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" type="password" [(ngModel)]="pass" (keydown)="checkPass($event)"
                    class="bgt">
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #chatpanel>
    <app-chat (triggerclosechat)="closeChat()"></app-chat>
</ng-template>

@if(!curentPartner?.hideovermenu) {
    <clever-overmenu></clever-overmenu>
}