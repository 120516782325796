import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class ToolsService {
    protected zoomid: string = 'z4Ahl4vuTc2h54-I17giFg';
    protected zoomUsername: string = 'Reisen';
    protected zoomEmail: string = 'zoom@sunfly.de';
    protected zoomPassword: string = 'sunflyde';
    protected siteKey = '6LeWXCAaAAAAANimLok9w69BC2BJloOlxwjl8ukE';

    // payement
    // protected payementurl = 'https://payments-test.traffics-ibe.com/';
    protected payementurl = 'https://payments.traffics-ibe.com/';
    constructor() {
    }

    getZoomID(): string {
        return this.zoomid;
    }

    getZoomUsername(): string {
        return this.zoomid;
    }

    getZoomEmail(): string {
        return this.zoomEmail;
    }

    getZoomPassword(): string {
        return this.zoomPassword;
    }

    getpayementurl(): string {
        return this.payementurl;
    }

    getcaptchasitekey(): string {
        return this.siteKey;
    }
}
