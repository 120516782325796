
/* eslint-env browser */
import {
    AfterViewInit,
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector:
        '[contenteditable][formControlName], [contenteditable][formControl], [contenteditable][ngModel]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContenteditableValueAccessor),
            multi: true
        },
    ],
})
export class ContenteditableValueAccessor implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit {
    @Input() chatmode: boolean = false;
    onTouched = () => { };
    onChange: (value: string) => void = () => { };
    private unsubscribe$ = new Subject();
    historyChange: BehaviorSubject<string>;
    curentIndex: number = 0;
    private history: string[] = [];

    // eslint-disable-next-line no-undef
    private observer = new MutationObserver(() => {
        setTimeout(() => {
            const text = ContenteditableValueAccessor.processValue(this.elementRef.nativeElement.innerHTML);
            this.historyChange.next(text);
            this.onChange(text);
        });
    });

    constructor(
        @Inject(ElementRef) private readonly elementRef: ElementRef,
        @Inject(Renderer2) private readonly renderer: Renderer2,
    ) {
        this.historyChange = new BehaviorSubject('');
    }

    ngAfterViewInit() {
        this.observMutation();
    }

    observMutation() {
        this.observer.observe(this.elementRef.nativeElement, {
            attributes: true,
            characterData: true,
            childList: true,
            subtree: true,
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.observer.disconnect();
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.initInputChange();
        if (!this.chatmode) {
            this.initHistoryChange();
            this.initKeyDownChange();
        } else {
            this.initKeyDownChangeAsChat();
        }
        this.initBlurChange();
        this.onPast();
    }

    initKeyDownChangeAsChat(): void {
        fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((event: KeyboardEvent) => {
            if (event.code === 'Enter' && event.shiftKey) {
                // event.preventDefault();
                setTimeout(() => {
                    const selection = window.getSelection();
                    const range = selection.getRangeAt(0);
                    if (range.startContainer.nodeName.toLocaleLowerCase() === 'div') {
                        const p = document.createElement('p');
                        const br = document.createElement('br');
                        p.appendChild(br);
                        range.deleteContents();
                        range.startContainer.parentNode.replaceChild(p, range.startContainer);
                        // range.setStartBefore(br);
                    }
                });
            }
        });
    }

    initKeyDownChange(): void {
        fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                event.preventDefault();
                event.stopPropagation();
                this.observer.disconnect();
                if (event.shiftKey) {
                    this.curentIndex++;
                    if (this.history[this.curentIndex]) {
                        this.elementRef.nativeElement.innerHTML = this.history[this.curentIndex];
                    } else {
                        this.curentIndex = this.history.length - 1;
                    }
                } else {
                    this.curentIndex--;
                    if (this.curentIndex >= 0) {
                        this.elementRef.nativeElement.innerHTML = this.history[this.curentIndex];
                    } else {
                        this.curentIndex = 0;
                    }
                }
                this.onChange(this.elementRef.nativeElement.innerHTML);
                this.observMutation();
            } else if (event.code === 'Enter') {
                // event.preventDefault();
                setTimeout(() => {
                    const selection = window.getSelection();
                    const range = selection.getRangeAt(0);
                    if (range.startContainer.nodeName.toLocaleLowerCase() === 'div') {
                        const p = document.createElement('p');
                        const br = document.createElement('br');
                        p.appendChild(br);
                        range.deleteContents();
                        range.startContainer.parentNode.replaceChild(p, range.startContainer);
                        // range.setStartBefore(br);
                    }
                });
            }
        });
    }

    initBlurChange(): void {
        fromEvent(this.elementRef.nativeElement, 'blur').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(_ => {
            this.onTouched();
        });
    }

    initHistoryChange(): void {
        this.historyChange.asObservable().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((element) => {
            if (element?.length > 0) {
                if (element !== this.history[this.curentIndex]) {
                    if (this.curentIndex >= 0 && this.history.length > 0 && this.curentIndex < this.history.length - 1) {
                        this.history = this.history.slice(0, this.curentIndex + 1);
                    }
                    this.history.push(element);
                    this.curentIndex = this.history.length - 1;
                }
            }
        });
    }

    initInputChange(): void {
        fromEvent(this.elementRef.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((ev: any) => {
            ev.stopPropagation();
            this.observer.disconnect();
            const text = ContenteditableValueAccessor.processValue(this.elementRef.nativeElement.innerHTML);
            if (text?.length === 0) {
                this.elementRef.nativeElement.innerHTML = '<p><br></p>';
            }
            this.historyChange.next(text);
            this.onChange(text);
            this.observMutation();
        });
    }

    onPast(): void {
        fromEvent(this.elementRef.nativeElement, 'paste').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((ev: any) => {
            ev.stopPropagation();
            ev.preventDefault();
            const clipboardData = ev.clipboardData;
            const pastedText = clipboardData.getData('text').trim();
            this._insertText(pastedText);
        });
    }

    _insertText(text) {
        const range = window.getSelection().getRangeAt(0);
        range.deleteContents();
        const textNode = document.createTextNode(text);
        if (range.collapsed && range.startContainer.nodeName.toUpperCase() === 'DIV') {
            // this.elementRef.nativeElement.innerHTML = '';
            const p = document.createElement('p');
            p.appendChild(textNode);
            range.insertNode(p);
            range.selectNodeContents(p);
            range.collapse(false);
        } else {
            range.insertNode(textNode);
            range.selectNodeContents(textNode);
            range.collapse(false);
        }
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        this.writeValue(this.elementRef.nativeElement.innerHTML)
    };

    getAncestorWithTagName(node, tagName) {
        tagName = tagName.toUpperCase();
        while (node) {
            if (node.nodeType === 1 && node.tagName.toUpperCase() === tagName) {
                return node;
            }
            node = node.parentNode;
        }
        return null;
    }

    registerOnChange(onChange: (value: string) => void) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    writeValue(value: any): void {
        const tmp_txt = ContenteditableValueAccessor.processValue(value).replaceAll('<p></p>', '').replaceAll('ng-star-inserted', '');
        let text = '';
        if (tmp_txt?.length > 0) {
            const tmp = ContenteditableValueAccessor.processValue(tmp_txt).split('\n');
            for (let i = 0; i < tmp.length; i++) {
                if (tmp[i]?.trim().length > 0) {
                    if (tmp[i].startsWith('<p>')) {
                        text += tmp[i];
                    } else {
                        text += '<p>' + tmp[i] + '</p>';
                    }
                } else {
                    text += '<p><br></p>';
                }
            }
        } else if (this.elementRef.nativeElement.innerHTML.length === 0) {
            text = '<p><br></p>';
        }
        this.historyChange.next(value);
        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            text,
        );
    }

    setDisabledState(disabled: boolean) {
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'contenteditable',
            String(!disabled),
        );
    }

    private static processValue(value: unknown): string {
        const processed = String(value == null ? '' : value);

        return processed.trim() === '<br>' ? '' : processed;
    }
}
