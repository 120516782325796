import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { AppBrowserModule } from './app/app.browser.module';

import { environment } from './environments/environment';

// setTimeout(() =>
//     platformBrowserDynamic().bootstrapModule(AppBrowserModule)
//         .catch(err => console.error(err))
// );

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        appConfig.providers
    ],
}).catch(err => console.log(err));
