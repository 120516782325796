<ng-container *ngIf="currentMitarbeiter()">
    <div class="mitarbeitercard" [class]="showChatAgent ? 'opened' : 'closed'">
        <button class="cc16 mat-elevation-z1" (click)="closeChatAgent($event)">
            <mat-icon aria-hidden="true" class="cc17">close</mat-icon>
        </button>
        <div class="cc15" (click)="openChat()">
            <div class="cc18 mat-elevation-z1">
                <div class="cc19">
                    <app-avatar [data]="currentMitarbeiter()?.avatar" [small]="true"></app-avatar>
                    <div class="cc20">
                        <span class="cc21">{{currentMitarbeiter()?.salutation}}
                            {{currentMitarbeiter()?.firstname}}
                            {{currentMitarbeiter()?.lastname}}</span>
                        <span>Telefon: <a href="tel:{{currentMitarbeiter()?.tel}}"
                                (click)="$event.stopPropagation();">{{currentMitarbeiter().tel}}</a></span>
                    </div>
                </div>
                <div class="cc111">
                    {{currentRobotMessage}}
                </div>
            </div>
        </div>
    </div>
</ng-container>