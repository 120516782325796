import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    constructor (
        private http: HttpClient,
    ) {}

    request (obj: Object, suffix: string): Observable<any> {
        // console.log(obj);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post('api/registration/' + suffix, { body: obj }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }
}
