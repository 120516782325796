import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../material.module';
import { ToolsModule } from '../tools/tools.module';
import { PipeModule } from '../../pipe/pipe.module';
import { FormularComponent } from './formular/formular.component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { AgentComponent } from './agent/agent.component';
import { DirectiveModule } from '@app/directives/directive.module';

@NgModule({
    declarations: [ChatComponent, FormularComponent, AgentComponent],
    imports: [
        CommonModule,
        FormsModule,
        AngularMaterialModule,
        ToolsModule,
        PipeModule,
        DirectiveModule,
        ReactiveFormsModule,
        RecaptchaV3Module
    ],
    exports: [
        ChatComponent,
        AgentComponent
    ]
})
export class ChatModule { }
