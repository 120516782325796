@if(unregistrationform && registrationform) {
    <div class="ccc1">
        <div [class.ccc2]="!smallMode" [class.ccc2m]="smallMode">
    
            <div class="ccc13">
                <img [src]="logo" class="imglogo">
            </div>
    
            @if(!data.unsubscribe) {
            @if(!registrationsended) {
            <div class="ccc14">
                <div class="ccc15">
                    <span class="ccc3">{{partner?.newsletterpopuptitle}}</span>
                </div>
    
                <div class="ccc15">
                    <span class="ccc4">{{partner?.newsletterpopuptext}}</span>
                </div>
                <form [formGroup]="registrationform">
                    <div class="ccc5">
                        <span class="ccc4">Name</span>
                        <input type="text" class="ccc6" formControlName="firstname" name="firstname">
                    </div>
    
                    <div class="ccc5">
                        <span class="ccc4">E-Mail Adresse *</span>
                        <input type="text" formControlName="email" name="email" class="ccc6">
                    </div>
                </form>
                <div class="ccc7">
                    <button (click)="setChecked()">
                        @if(registrationform.value.check) {
                        <mat-icon>check_box</mat-icon>
                        } @else {
                        <mat-icon>check_box_outline_blank</mat-icon>
                        }
                    </button>
                    <span class="ccc4 ccc10">* Ja ich möchte regelmäßig über Angebote zum Thema Urlaub und Reisen von
                        {{partner.firmenname}}
                        informiert werden und akzeptiere die <span class="cc7"
                            (click)="gotodataschutz($event)">Datenschutzerklärung</span>.
                    </span>
                </div>
    
                <div class="errorf">
                    <span>* Mussfelder</span>
                </div>
    
                <div class="ccc8">
                    <button class="ccc9" (click)="registrierenF()" [disabled]="loading"> Jetzt anmelden!</button>
                </div>
            </div>
            } @else {
            <div class="cc9">
                <span class="ccc3">Newsletter Bestätigungs-Mail wurde versandt</span>
                <span class="ccc4">
                    Es wird eine e-Mail an Ihre Adresse {{email}} versendet. Bitte bestätigen Sie die Anmeldung duch den
                    Link in
                    der e-Mail.
                    Sollten Sie keine e-Mail erhalten haben, prüfen Sie bitte den Spam-Ordner Ihres Postfaches.
                </span>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="onNoClick()">Schließen</button>
            </div>
            }
            } @else {
            @if(!unregistrationsended) {
            <div class="ccc14">
                <div class="ccc15">
                    <span class="ccc3">Hier können Sie sich vom Newsletter abmelden.</span>
                </div>
                <form [formGroup]="unregistrationform">
                    <div class="ccc5">
                        <span class="ccc4">E-Mail Adresse *</span>
                        <input type="text" class="ccc6" formControlName="email" name="email">
                    </div>
                </form>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="unregistrierenF()" [disabled]="loading">ja, vomNewsletter abmelden</button>
            </div>
            } @else {
            <div class="cc9">
                <span class="ccc4">Sie wurden vom Newsletter abgemeldet. Aus technischen Gründen kann es sein, dass Sie in
                    den
                    nächsten Stunden dennoch noch einen Newsletter erhalten. Wir bitten dies zu entschuldigen.</span>
            </div>
    
            <div class="ccc8">
                <button class="ccc9" (click)="onNoClick()">Schließen</button>
            </div>
            }
            }
        </div>
        @if(!smallMode) {
        <div class="ccc12">
            <img src="../../../../assets/images/newsletterimg.jpg">
        </div>
        }
        @if(error?.length > 0) {
        <div>
            <span>{{error}}</span>
        </div>
        }
        <button [class.ccc16m]="smallMode" [class.ccc16]="!smallMode" (click)="onNoClick()">
            <mat-icon class="ccc17">close</mat-icon>
        </button>
    </div>
    }