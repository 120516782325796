import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class GlobalcontentService {
    constructor (
        private http: HttpClient,
        @Inject(DOCUMENT) protected document: any,
    ) {}

    request (obj: Object, suffix: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post('api/globalcontent/' + suffix, { body: obj }, httpOptions).pipe(
            catchError(res => of(res))
        );
    }

    loadjsonld (schema): void {
        const gaScript = this.document.createElement('script');
        gaScript.setAttribute('type', 'application/ld+json');
        gaScript.text = JSON.stringify(schema);
        this.document.documentElement.firstChild.appendChild(gaScript);
    }
}
