import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieComponent } from './cookie.component';
import { AngularMaterialModule } from '../../material.module';

@NgModule({
    declarations: [
        CookieComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule
    ],

    exports: [
        CookieComponent
    ],

    providers: [
    ]
})

export class CookieModule { }
