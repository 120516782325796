<div class="mat-elevation-z5" [class.chatwraperMobile]="desktopTemplate === 'xs'"
    [class.chatwraper]="desktopTemplate !== 'xs'">
    <div class="chatwrp">
        <div class="cc2">
            <button class="cc22" (click)="newChat()">
                <mat-icon aria-hidden="true" class="cc8" matTooltip="neu starten">replay</mat-icon>
                <span>neu starten</span>
            </button>
            <button class="cc22" (click)="closeChat()">
                <mat-icon aria-hidden="true" class="cc8" matTooltip="Schließen">close</mat-icon>
            </button>
        </div>
        <ng-container *ngTemplateOutlet="messageArea"></ng-container>
        <span *ngIf="typing" class="cc7">Tippen...</span>
        <ng-container *ngTemplateOutlet="inputsection"></ng-container>
        <ng-container *ngTemplateOutlet="chatConf"></ng-container>
        <ng-container *ngIf="showbigImage">
            <div class="cc31c">
                <div class="cc34c">
                    <img data-src="{{selectedblb}}" class="lazyload cc32c">
                </div>
                <div class="cc33c">
                    <button class="cc36c" (click)="closeImgModal()">Schließen</button>
                    <button class="cc35c" *ngIf="sendImgBtn" (click)="sendImmage()">Senden</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #messageArea>
    <div class="messageArea" id="msgContainer">
        <div>
            <ng-container *ngFor="let msg of messages">
                <ng-container *ngIf="msg.from?.username === IchatUser?.username; else oth">
                    <ng-container *ngTemplateOutlet="mymsg; context:{msg:msg}">
                    </ng-container>
                </ng-container>
                <ng-template #oth>
                    <ng-container *ngTemplateOutlet="clevertmsg; context:{msg:msg}">
                    </ng-container>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="showwaiting30secLoading" [ngTemplateOutlet]="waiting30secLoading"></ng-container>
            <ng-container *ngIf="showmb1" [ngTemplateOutlet]="mb1"></ng-container>
            <ng-container *ngIf="showmb2" [ngTemplateOutlet]="mb2"></ng-container>
            <ng-container *ngIf="showmb3" [ngTemplateOutlet]="mb3"></ng-container>
            <ng-container *ngIf="showmb4" [ngTemplateOutlet]="mb4"></ng-container>
            <ng-container *ngIf="showmb5" [ngTemplateOutlet]="mb5"></ng-container>
            <ng-container *ngIf="showmb6" [ngTemplateOutlet]="mb6"></ng-container>
            <ng-container *ngIf="showmb7" [ngTemplateOutlet]="mb7"></ng-container>

            <ng-container *ngIf="showphonsktvformular" [ngTemplateOutlet]="sktvformular"></ng-container>
            <ng-container *ngIf="showbookingformular" [ngTemplateOutlet]="bookingformular"></ng-container>
            <ng-container *ngIf="showphonformular" [ngTemplateOutlet]="phonformular"></ng-container>
            <ng-container *ngIf="showmailformular" [ngTemplateOutlet]="mailformular"></ng-container>
            <ng-container *ngIf="showandereFrageformular" [ngTemplateOutlet]="andereFrageformular"></ng-container>
            <ng-container *ngIf="showandereFrageformular1" [ngTemplateOutlet]="andereFrageformular1"></ng-container>
            <ng-container *ngIf="showandereFrageformular2" [ngTemplateOutlet]="andereFrageformular2"></ng-container>
        </div>
    </div>
</ng-template>


<ng-template #mb1>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2"
            (click)="startReisebuchung('Ich habe eine Frage zu einer bestehenden Reisebuchung.')">Ich habe eine Frage zu
            einer bestehenden Reisebuchung.</button>
        <ng-container *ngIf="partner && !(partner.nosktv)">
            <button class="btn-cc2" (click)="startsktv('Ich habe eine Frage zu einer sonnenklar.TV Bestellnummer.')">Ich
                habe eine Frage zu einer sonnenklar.TV
                Bestellnummer.</button>
        </ng-container>
        <button class="btn-cc2" (click)="startNeueReise('Ich wünsche eine Beratung für eine neue Reise.')">Ich wünsche
            eine Beratung für eine neue Reise.</button>
            <button class="btn-cc2" (click)="gotoAnfrage('zum digitalen Assistenten.')">zum digitalen Assistenten.</button>
    </div>
</ng-template>

<ng-template #mb2>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" (click)="startWeitereWachten('Ich möchte weiter warten.')">Ich möchte weiter
            warten.</button>
        <ng-container *ngTemplateOutlet="genbtns"></ng-container>
    </div>
</ng-template>

<ng-template #mb3>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" *ngIf="showmb3whaite" (click)="startWeitereWachten('Ich möchte weiter warten.')">Ich
            möchte weiter
            warten.</button>
        <button class="btn-cc2"
            (click)="gotoReisekompass('Ich habe eine Frage zu den aktuellen Einreisebestimmungen.', rkp1)">Ich habe eine
            Frage zu den aktuellen Einreisebestimmungen.</button>
            <button class="btn-cc2" (click)="gotoAnfrage('zum digitalen Assistenten.')">zum digitalen Assistenten.</button>
        <button class="btn-cc2"
            (click)="gotoReisekompass('Ich habe eine Frage zu der aktuellen Corona-Situation in meinem Reiseziel.', rkp2)">Ich
            habe eine Frage zu der aktuellen Corona-Situation in meinem Reiseziel.</button>
        <button class="btn-cc2" (click)="openandereFrageformular()">Ich habe eine
            Frage zu einem anderen Thema.</button>
        <button class="btn-cc2" (click)="returnmb2()"> Ich möchte einen
            Schritt zurückgehen.</button>
    </div>
</ng-template>

<ng-template #mb4>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" (click)="startWeitereWachtenV4('Ich möchte weiter warten.')">Ich möchte weiter
            warten.</button>
        <ng-container *ngTemplateOutlet="genbtns"></ng-container>
    </div>
</ng-template>

<ng-template #mb5>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" *ngIf="showmb4whaite" (click)="startWeitereWachtenV4('Ich möchte weiter warten.')">Ich
            möchte weiter
            warten.</button>
        <button class="btn-cc2" (click)="gotoAnfrage('zum digitalen Assistenten.')">zum digitalen Assistenten.</button>
        <button class="btn-cc2" (click)="openandereFrageformular1()"> Ich habe eine andere Frage.</button>
        <button class="btn-cc2" (click)="returnmb3()"> Ich möchte einen Schritt zurückgehen.</button>
    </div>
</ng-template>

<ng-template #mb6>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" (click)="startWeitereWachtenV5('Ich möchte weiter warten.')">Ich möchte weiter
            warten.</button>
        <ng-container *ngTemplateOutlet="genbtns"></ng-container>
    </div>
</ng-template>

<ng-template #mb7>
    <div class="btn-cc1">
        <span class="ft">Bitte wähle schon mal eine Option aus</span>
        <button class="btn-cc2" *ngIf="showmb5whaite" (click)="startWeitereWachtenV5('Ich möchte weiter warten.')">Ich
            möchte weiter
            warten.</button>
        <ng-container *ngIf="partner && !(partner.nosktv)">
            <ng-container *ngTemplateOutlet="sktvtemplate"></ng-container>
        </ng-container>
        <button class="btn-cc2" (click)="openandereFrageformular2()">Ich habe eine Frage zu einem anderen
            Thema.</button>
            <button class="btn-cc2" (click)="gotoAnfrage('zum digitalen Assistenten.')">zum digitalen Assistenten.</button>
        <button class="btn-cc2" (click)="returnmb4()"> Ich möchte einen
            Schritt zurückgehen.</button>
    </div>
</ng-template>

<ng-template #sktvformular>
    <div class="btn-cc1">
        <mat-form-field appearance="fill" color="primary">
            <mat-label>Bestellnummer *</mat-label>
            <input matInput placeholder="Bestellnummer *" [(ngModel)]="sktvnumber">
        </mat-form-field>
        <span class="error" *ngIf="sktvnumbererror">* Bestellnummer (Pflichtfeld)</span>
        <div class="cc6skk">
            <button class="fbtn-s" (click)="gotobacksktv()">Zurück</button>
            <button class="fbtn-s" (click)="gotSktv()">zum Angebot</button>
            <button class="fbtn-se" (click)="sendsktvnumber()">weitere Fragen</button>
        </div>
    </div>
</ng-template>

<ng-template #bookingformular>
    <div class="btn-cc1">
        <mat-form-field appearance="fill" color="primary">
            <mat-label>Buchungsnummer *</mat-label>
            <input matInput placeholder="Buchungsnummer *" [(ngModel)]="bookingnumber">
        </mat-form-field>
        <span class="error" *ngIf="bookingnumberrerror">* Buchungsnummer (Pflichtfeld)</span>
        <div class="cc6skk">
            <button class="fbtn-s" (click)="gotobackbooking()">Zurück</button>
            <button class="fbtn-se" (click)="sendbookingnumber()">weitere Fragen</button>
        </div>
    </div>
</ng-template>

<ng-template #genbtns>
    <button class="btn-cc2" (click)="gotoAnfrage('zum digitalen Assistenten.')">zum digitalen Assistenten.</button>
    <button class="btn-cc2" (click)="gotoCalendar()">Ich vereinbare
        einen persönlichen Termin.</button>
    <button class="btn-cc2" (click)="showMail()">Ich schicke eine Email.</button>
    <button class="btn-cc2" (click)="showPhonNumber()">Ich bitte um einen Rückruf.</button>
    <button class="btn-cc2" (click)="returnmb1()"> Ich möchte einen
        Schritt zurückgehen.</button>
</ng-template>

<ng-template #inputsection>
    <div [class.messageBottomMobile]="desktopTemplate === 'xs'" [class.messageBottom]="desktopTemplate !== 'xs'">
        <ng-container *ngIf="imgPermission">
            <button (click)="openFilesBrowser($event)" matTooltip="Bilder">
                <mat-icon aria-hidden="true">attach_file</mat-icon>
            </button>
        </ng-container>
        <input #filesBrowser type="file" max-size="2000" style="visibility: hidden;width:0px;"
                    (change)="multipleFilesChangeEvent($event);" (click)="filesBrowser.value = null;"
                    accept=".jpg, .png, .jpeg, .webp" />
        <form class="cc13" *ngIf="trackForm" [formGroup]="trackForm">
            <div contenteditable [chatmode]="true" class="cc27c customscrollmenu" (keypress)="keypress($event)"
                formControlName="text" #myEditable>
            </div>
            <ng-container *ngIf="myEditable.textContent.length === 0">
                <div class="cc26c">Schreibe eine Nachricht</div>
            </ng-container>
            <!-- <input type="text" [(ngModel)]="msgInput" placeholder="Schreibe eine Nachricht" class="customInput"
                (keypress)="keypress($event)" ng-focus [appAutoFocus]="focus"> -->
        </form>

        <div class="cc14">
            <button mat-icon-button (click)="sendButtonClick()" matTooltip="Senden">
                <mat-icon aria-hidden="true">send</mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #chatConf>
    <ng-container *ngIf="reloadconfirmation">
        <div class="chatres-cc1">
            <div class="chatres-cc3">
                <span class="chatres-cc6">Möchten Sie den Chat neu starten?</span>
                <span class="chatres-cc5">Ihr bisheriger Verlauf wird nicht gespeichert.</span>
                <div class="chatres-cc2">
                    <button class="chatconfbtn" (click)="reloadconfirmation = false;">weiterschreiben</button>
                    <button class="chatconfbtn btnright" (click)="resetChat()">neu starten</button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #mymsg let-msg="msg">
    <div class="cc9-my">
        <div class="cc9-my-wrp">
            <div class="mymessage">
                <ng-container [ngSwitch]="msg.type">
                    <ng-template [ngSwitchCase]="'zoom'">
                        <mat-icon aria-hidden="true" class="clicable cc10" (click)="gotozoom(msg.message)">
                            videocam</mat-icon>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'image'">
                        <div class="cc38" (click)="openBigImage(msg.message)">
                            <img data-src="{{msg.message}}" class="lazyload cc37">
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchDefault]>
                        <div class="cc10c" [innerHTML]="msg.message | linkify:false | safehtml"></div>
                    </ng-template>
                </ng-container>
            </div>
            <span class="cc11">{{msg.date |date:'dd.MM.yyyy,HH:mm'}}</span>
        </div>
    </div>
</ng-template>

<ng-template #clevertmsg let-msg="msg">
    <div class="cc9-clever-wrp">
        <ng-container *ngIf="getAvatar(msg?.from) else icorob">
            <div class="chatavatarimg">
                <img data-src="{{msg.from.avatar}}" alt="{{msg.from.firstname}}" class="lazyload cleverimgav">
            </div>
        </ng-container>
        <ng-template #icorob>
            <div class="chatavatar">
                <mat-icon class="avatarrobot">smart_toy</mat-icon>
            </div>
        </ng-template>
        <div class="cc9-clever">
            <ng-container *ngIf="msg?.from?.firstname?.length > 0">
                <span class="cc12">{{msg.from.firstname}}
                    {{msg.from.lastname}}</span>
            </ng-container>
            <div class="othermessage">
                <ng-container [ngSwitch]="msg.type">
                    <ng-template [ngSwitchCase]="'zoom'">
                        <mat-icon aria-hidden="true" class="clicable cc10" (click)="gotozoom(msg.message)">
                            videocam</mat-icon>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'image'">
                        <div class="cc38" (click)="openBigImage(msg.message)">
                            <img data-src="{{msg.message}}" class="lazyload cc37">
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchDefault]>
                        <div class="cc10c" [innerHTML]="msg.message | linkify | safehtml"></div>
                    </ng-template>
                </ng-container>
            </div>
            <span class="cc11">{{msg.date |date:'dd.MM.yyyy,HH:mm'}}</span>
        </div>
    </div>
</ng-template>

<ng-template #sktvtemplate>
    <button class="btn-cc2" (click)="showTvInputChat = true" #mdbuttonChatSktv="cdkOverlayOrigin" cdkOverlayOrigin>Ich
        interessiere mich für mehr Informationen zu dem sonnenklar.TV Angebot.</button>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="mdbuttonChatSktv"
        [cdkConnectedOverlayFlexibleDimensions]="true" [cdkConnectedOverlayOpen]="showTvInputChat"
        [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="showTvInputChat = false">
        <div class="tmp_srchwrp">
            <input type="text" class="tmpangebotsktvinput" [(ngModel)]="sktvnumber"
                placeholder="TV-Bestellnummer, TV-Angebote/Hotels">
            <button class="tmp_srchbtn" (click)="gotSktv()" matRipple>
                <span>Suchen</span>
            </button>
        </div>
    </ng-template>
</ng-template>

<ng-template #waiting30secLoading>
    <div class="btn-cc11">
        <!-- <mat-spinner [diameter]="20"></mat-spinner> -->
        <button class="zbtn-cc11" (click)="gobackinmenu()">
            <mat-icon class="zbtn-cc11-ico">arrow_back_ios_new</mat-icon>
            Zurück
        </button>
        <span class="timer">{{timer30}} ...</span>
    </div>
</ng-template>

<ng-template #phonformular>
    <div class="btn-cc1">
        <clever-formular (sendFormular)="sendFormular($event, msfr1)" (closeFormular)="closeFormular($event)">
        </clever-formular>
    </div>
</ng-template>

<ng-template #mailformular>
    <div class="btn-cc1">
        <clever-formular (sendFormular)="sendFormular($event, msfr2)" [ismail]="true"
            (closeFormular)="closeFormular($event)">
        </clever-formular>
    </div>
</ng-template>

<ng-template #andereFrageformular>
    <div class="btn-cc1">
        <clever-formular (sendFormular)="sendFormular($event, msfr2)" [ismail]="true"
            (closeFormular)="closeAndereFrageFormular($event)">
        </clever-formular>
    </div>
</ng-template>

<ng-template #andereFrageformular1>
    <div class="btn-cc1">
        <clever-formular (sendFormular)="sendFormular($event, msg5 + currentFrom?.firstname + msg51)" [ismail]="true"
            (closeFormular)="closeAndereFrageFormular1($event)">
        </clever-formular>
    </div>
</ng-template>

<ng-template #andereFrageformular2>
    <div class="btn-cc1">
        <clever-formular (sendFormular)="sendFormular($event, msg7 + currentFrom?.firstname + msg71)" [ismail]="true"
            (closeFormular)="closeAndereFrageFormular2($event)">
        </clever-formular>
    </div>
</ng-template>


<ng-template #imgmdl>
    <div class="cc39">
        <img class="lazyload cc32" data-src="{{currentimage}}">
        <button class="cc33" matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
        <button class="cc33cc" (click)="downloadImg()">
            <mat-icon>download</mat-icon>
        </button>
    </div>
</ng-template>