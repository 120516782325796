import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class PartnerService {
    constructor(
        private http: HttpClient
    ) { }

    request(obj: Object, suffix: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post('api/partner/' + suffix, { body: obj }, httpOptions).pipe(
            catchError(res => of(res))
        );
    }
}
