import { Injectable, HostListener, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer } from '../models/customers/Customer';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private unsubscribe$ = new Subject();
    currentCustomer: Customer;

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    get auth$() {
        return this.contentAuthSubject.asObservable();
    }

    private contentAuthSubject: BehaviorSubject<Customer>;

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.contentAuthSubject = new BehaviorSubject(undefined);
    }

    async setAuth(customer: Customer) {
        this.currentCustomer = customer;
        this.contentAuthSubject.next(customer);
    }

    async resetCustomer(currentpartnerid) {
        this.resetSession(currentpartnerid);
        this.currentCustomer = {} as Customer;
        this.contentAuthSubject.next(this.currentCustomer);
    }

    auth(email: String, password: string, partnerid: string, asPartner: boolean): Observable<Customer> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post<Customer>('api/customer/login', { body: { email: email, password: password, partnerid: partnerid, asPartner: asPartner } }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }

    reconnect(token: string): Observable<Customer> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': '',
                'x-access-token': token
            })
        };
        return this.http.post<Customer>('api/customer/reconnect', { body: { token: token } }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }

    logout(currentpartnerid): Observable<Customer> {
        const token = window.localStorage.getItem(currentpartnerid + '_access_token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': '',
                'x-access-token': token
            })
        };
        return this.http.post<Customer>('api/customer/logout', { body: { token: token } }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }

    request(obj, suffix: string): Observable<Customer> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post<Customer>('api/customer/' + suffix, { body: obj }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }

    isAuthenticated(currentpartnerid): Promise<boolean> {
        return new Promise(cb => {
            // const partnerid = window.localStorage.getItem(currentpartnerid + '_partnerid');
            const token = window.localStorage.getItem(currentpartnerid + '_access_token');
            // const email = window.localStorage.getItem(currentpartnerid + '_email');
            // const asPartner = window.localStorage.getItem(currentpartnerid + '_asPartner');
            if (token?.length > 0) {
                if (!this.currentCustomer) {
                    this.reconnect(token).pipe(
                        takeUntil(this.unsubscribe$)
                    ).subscribe((res: Customer) => {
                        if (!res?.error) {
                            // res.asPartner = asPartner === 'true';
                            this._setSession(res);
                            this.setAuth(res);
                            cb(true);
                        } else {
                            this.resetSession(currentpartnerid);
                            this.setAuth({} as Customer);
                            cb(false);
                        }
                    });
                } else {
                    cb(true);
                }
            } else {
                this.resetSession(currentpartnerid);
                this.setAuth({} as Customer);
                cb(false);
            }
        });
    }

    _setSession(authResult: Customer) {
        if (isPlatformBrowser(this.platformId)) {
            // Save session data and update login status subject
            // window.localStorage.setItem(authResult.partnerid + '_asPartner', String(authResult.asPartner));
            window.localStorage.setItem(authResult.partnerid + '_access_token', authResult.token);
            // window.localStorage.setItem(authResult.partnerid + '_partnerid', authResult.partnerid);
            // window.localStorage.setItem(authResult.partnerid + '_email', authResult.email);
            // window.localStorage.setItem(authResult.partnerid + '_firstname', authResult.firstname);
            // window.localStorage.setItem(authResult.partnerid + '_lastname', authResult.lastname);
            // window.localStorage.setItem(authResult.partnerid + '_asPartner', String(authResult?.asPartner));
            // window.localStorage.setItem(authResult.partnerid + '_superuser', String(authResult?.superuser));
        }
    }

    resetSession(partnerid) {
        if (isPlatformBrowser(this.platformId)) {
            window.localStorage.removeItem(partnerid + '_access_token');
            // window.localStorage.removeItem(partnerid + '_partnerid');
            // window.localStorage.removeItem(partnerid + '_email');
            // window.localStorage.removeItem(partnerid + '_firstname');
            // window.localStorage.removeItem(partnerid + '_lastname');
            // window.localStorage.removeItem(partnerid + '_asPartner');
            // window.localStorage.removeItem(partnerid + '_superuser');
        }
    }
}
