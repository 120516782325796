import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class TerminkalenderService {
    constructor(
        private http: HttpClient,
    ) { }

    request(obj, suffix: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post<any>('api/terminkalender/' + suffix, { body: obj }, httpOptions)
            .pipe(
                distinctUntilChanged(),
                catchError(res => of(res))
            );
    }
}
